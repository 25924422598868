// Imports
          import { Link } from 'react-router-dom'
import InputText from "../../../../components/inputs/InputText";
import InputPhoneNumber from "../../../../components/inputs/inputPhoneNumber/InputPhoneNumber";
import InputTextArea from "../../../../components/inputs/InputTextArea";
import { showModalOK  } from '../../../../components/modal'
import React, { useEffect, useMemo,useContext } from 'react';

import { useState } from 'react';

import '../../../../App.css';
import { useNavigate } from 'react-router-dom';


import * as fa from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { api } from "../../../../service/mftc-api";


function Component111Page() {
const navigate = useNavigate()


const [content,setContent]= useState( {"Phonenumber":"+33626416598","Avis":[]} )   

const setInternal= (key,value)=>{ setContent(c=> ({...c, [key]:value}) );  }

//States


//Events
const bloc0_1_onClick = async (e) => {
                try{ 
                      navigate('/home');
                }
                catch(e){
                  console.log("Error bloc0_1_onClick ")
                }  
              };

const action_503 =   async ({Phonenumber,setPhonenumber})=> {
        try { 
        if(Phonenumber)
window.location="tel:" + Phonenumber;
}
        catch (error) {
        console.error(error);
        }
      };  

const setPhonenumberbloc1_2_0 =(value)=> setInternal("Phonenumber",value);

const action_event_503_bloc1_2_0 =  async(event)=>{

                            
                           var Phonenumber  = "+33626416598" ;
              
                          await action_503( { Phonenumber,event, setPhonenumber:setPhonenumberbloc1_2_0  }) 
              
                          };  

const action_504 =   async ({Phonenumber,setPhonenumber})=> {
        try { 
         if(Phonenumber)
 window.location="https://wa.me/" + Phonenumber.split(".").join("").replace("+","");
     
}
        catch (error) {
        console.error(error);
        }
      };  

const setPhonenumberbloc1_2_1 =(value)=> setInternal("Phonenumber",value);

const action_event_504_bloc1_2_1 =  async(event)=>{

                            
                           var Phonenumber  = "+33626416598" ;
              
                          await action_504( { Phonenumber,event, setPhonenumber:setPhonenumberbloc1_2_1  }) 
              
                          };  

useEffect(()=>{  action_event_469_bloc4()   },[])

const bloc4_4_onClick = async (e) => {
                try{ 
                     window.open('https://www.google.com/maps/place/BMN+TAXI%E2%80%99S/@46.2016223,5.2432073,17z/data=!4m8!3m7!1s0xaca0a1a9f866f005:0x183ea28c95b3116c!8m2!3d46.2016223!4d5.2457822!9m1!1b1!16s%2Fg%2F11sy_621hp?entry=ttu','_blank');
                }
                catch(e){
                  console.log("Error bloc4_4_onClick ")
                }  
              };

const action_469 =   async ({Reviews,place_id,API_KEY,language,Summary,setReviews,setplace_id,setAPI_KEY,setlanguage,setSummary})=> {
        try { 
        
      var resultSummary =  await api("/ploy8_get_google_reviews_469_0", {body:{Reviews,place_id,API_KEY,language,Summary},log:true });
       console.log("Result Back", resultSummary)  ;
      

setReviews(resultSummary.reviews)
setSummary(` ${resultSummary.rating}/5  NOTE MOYENNE SUR ${resultSummary.user_ratings_total}`)
}
        catch (error) {
        console.error(error);
        }
      };  

const setReviewsbloc4 =(value)=> setInternal("Avis",value);

const setplace_idbloc4 =(value)=> setInternal("place_id",value);

const setAPI_KEYbloc4 =(value)=> setInternal("API_KEY",value);

const setlanguagebloc4 =(value)=> setInternal("language",value);

const setSummarybloc4 =(value)=> setInternal("Resume",value);

const action_event_469_bloc4 =  async(event)=>{

                            
                           var Reviews = content["Avis"];
var place_id  = "ChIJBfBm-KmhoKwRbBGzlYyiPhg" ;
var API_KEY  ;
var language  = "fr" ;
var Summary = content["Resume"];
              
                          await action_469( { Reviews,place_id,API_KEY,language,Summary,event, setReviews:setReviewsbloc4 , setplace_id:setplace_idbloc4 , setAPI_KEY:setAPI_KEYbloc4 , setlanguage:setlanguagebloc4 , setSummary:setSummarybloc4  }) 
              
                          };  

const action_437 =   async ({MailAddresse,Message,setMailAddresse,setMessage})=> {
        try { 
        
      var result =  await api("/ploy8_envoi_formulaire_par_mail_437_0", {body:{MailAddresse,Message},log:true });
       console.log("Result Back", result)  ;
      

window.alert("Message envoyé avec succès!" )
showModalOK();
setMessage({});
}
        catch (error) {
        console.error(error);
        }
      };  

const setMailAddressebloc6_2_4_0 =(value)=> setInternal("MailAddresse",value);

const setMessagebloc6_2_4_0 =(value)=> setInternal("MessageContact",value);

const action_event_437_bloc6_2_4_0 =  async(event)=>{

                            
                           var MailAddresse  = "bmn.taxis@gmail.com" ;
var Message = content["MessageContact"];
              
                          await action_437( { MailAddresse,Message,event, setMailAddresse:setMailAddressebloc6_2_4_0 , setMessage:setMessagebloc6_2_4_0  }) 
              
                          };  

const bloc7_0_3_onClick = async (e) => {
                try{ 
                      navigate('/legal');
                }
                catch(e){
                  console.log("Error bloc7_0_3_onClick ")
                }  
              };

//UseEffect




//DIV
  return <><div id='bloc0'  className={ "flex flex-row items-center bg-zinc-700 safearea w-screen bg-secondary_color bg-opacity-50 justify-between"}     > <img  id='bloc0_0' src="/profiles/ploy8/user.png" className={ "rounded-xl m-6 h-16 w-16   object-cover"}     alt="undefined" />
          <Link    to="/home"><div id='bloc0_1'  className={ "p-2 font-bold text-xl text-primary_color"}    onClick = { bloc0_1_onClick } >{`BMN TAXI'S`} </div></Link>
          <FontAwesomeIcon  id='bloc0_2'  icon={ fa.faTaxi}      className={ "h-16 w-16 m-6 text-primary_color"}  /></div><div id='bloc1'  className={ "w-screen flex flex-col overflow-hidden relative safearea"}   style = { {"height":"100vw","maxHeight":"70vh"}}   > <img  id='bloc1_0' src="https://fs.appisyou.com/apps/bmntaxis/photoanis21721066043213.jpeg" className={ "w-screen absolute top-0 left-0 h-full   object-cover"}     style = { {"objectPosition":" 50% 80%"}} alt="undefined" />
          <div id='bloc1_1'  className={ "text-white text-2xl grow z-20 flex flex-col justify-start p-4 items-end"}     > <div id='bloc1_1_0'  className={ "text-white z-20 flex flex-col bg-black p-2 bg-opacity-50 justify-end items-end text-sm"}     > <div id='bloc1_1_0_0'  className={ "flex centre flex-row-reverse  items-center justify-center"}     > <FontAwesomeIcon  id='bloc1_1_0_0_0'  icon={ fa.faHospitalSymbol}      className={ "p-1"}  />
                <div id='bloc1_1_0_0_1'  className={ "font-bold p-1"}     >{`TAXI conventionné CPAM`} </div></div>
              <div id='bloc1_1_0_1'  className={ "flex centre flex-row-reverse  items-center justify-center"}     > <FontAwesomeIcon  id='bloc1_1_0_1_0'  icon={ fa.faPlaneDeparture}      className={ "p-1"}  />
                <div id='bloc1_1_0_1_1'  className={ "font-bold p-1"}     >{`Aéroports et Gares`} </div></div>
              <div id='bloc1_1_0_2'  className={ "flex centre flex-row-reverse  items-center justify-center"}     > <FontAwesomeIcon  id='bloc1_1_0_2_0'  icon={ fa.faRoad}      className={ "p-1"}  />
                <div id='bloc1_1_0_2_1'  className={ "font-bold p-1"}     >{`Grands trajets`} </div></div>
              <div id='bloc1_1_0_3'  className={ "flex centre flex-row-reverse  items-center justify-center"}     > <FontAwesomeIcon  id='bloc1_1_0_3_0'  icon={ fa.faClockRotateLeft}      className={ "p-1"}  />
                <div id='bloc1_1_0_3_1'  className={ "font-bold p-1"}     >{`24/24 7/7`} </div></div>
              <div id='bloc1_1_0_4'  className={ "flex centre flex-row-reverse  items-center justify-center"}     > <FontAwesomeIcon  id='bloc1_1_0_4_0'  icon={ fa.faUserTie}      className={ "p-1"}  />
                <div id='bloc1_1_0_4_1'  className={ "font-bold p-1"}     >{`Chauffeur privé`} </div></div>
              <div id='bloc1_1_0_5'  className={ "flex centre flex-row-reverse  items-center justify-center"}     > <FontAwesomeIcon  id='bloc1_1_0_5_0'  icon={ fa.faPeopleGroup}      className={ "p-1"}  />
                <div id='bloc1_1_0_5_1'  className={ "font-bold p-1"}     >{`Transport de groupe`} </div></div>
              <div id='bloc1_1_0_6'  className={ "flex centre flex-row-reverse  items-center justify-center"}     > <FontAwesomeIcon  id='bloc1_1_0_6_0'  icon={ fa.faLuggageCart}      className={ "p-1"}  />
                <div id='bloc1_1_0_6_1'  className={ "font-bold p-1"}     >{`Transport de colis`} </div></div></div></div>
          <div id='bloc1_2'  className={ "p-2 w-full left-0 flex centre bottom-4 fixed z-30 items-center justify-center  items-center justify-center"}     > <div id='bloc1_2_0'  className={ "font-semibold py-2 rounded-2xl appbutton flex flex-row centre border-white border-4 text-lg px-4 text-secondary_color bg-primary_color  items-center justify-center"}    onClick = { action_event_503_bloc1_2_0} > <FontAwesomeIcon  id='bloc1_2_0_0'  icon={ fa.faPhone}      className={ "p-2"}  />
              <div id='bloc1_2_0_1'  className={ "p-2"}     >{ content["Phonenumber"]} </div></div>
            <div id='bloc1_2_1'  className={ "bg-green-500 border-white border-4 rounded-full p-2 ml-4"}    onClick = { action_event_504_bloc1_2_1} > <FontAwesomeIcon  id='bloc1_2_1_0'  icon={ fa.faPhone}      className={ "p-2 text-white text-lg"}  /></div></div>
          <div id='bloc1_3'  className={ "absolute  width=100vw z-30 top-0 left-0 safearea flex flex-row justify-end items-end"}   style = { {"width":"100vw"}}   > </div></div><div id='bloc2'  className={ "w-full bg-zinc-100 p-6 flex flex-col justify-center items-start text-zinc-900 py-8"}     > <div id='bloc2_0'  className={ "text-4xl font-bold text-left my-2 px-4"}     >{`BMN pour vous servir !`} </div>
          <div id='bloc2_1'  className={ "flex flex-col justify-start items-start md:grid grid-cols-3 "}     > <div id='bloc2_1_0'  className={ "flex flex-col items-start p-4"}     > <div id='bloc2_1_0_0'  className={ "font-bold text-lg"}     >{`Transport Conventionné CPAM  `} </div>
              <div id='bloc2_1_0_1'  className={ " my-2 text-lg text-left max-w-5/6"}     >{`BMN Taxis vous propose un service de transport sanitaire conventionné CPAM pour vos déplacements médicaux. Nos chauffeurs sont formés pour assurer votre confort et sécurité lors de vos trajets vers les hôpitaux. Nous nous engageons à vous accompagner avec ponctualité et bienveillance, en toute sérénité.`} </div></div>
            <div id='bloc2_1_1'  className={ "flex flex-col items-start p-4"}     > <div id='bloc2_1_1_0'  className={ "font-bold text-lg"}     >{`Disponibilité 24/7 ⏰`} </div>
              <div id='bloc2_1_1_1'  className={ " my-2 text-lg text-left max-w-5/6"}     >{`BMN Taxis est disponible 24h/24, 7j/7 pour tous vos déplacements en France. Que ce soit pour un trajet urgent ou une course planifiée, vous pouvez compter sur nous à tout moment. Nos chauffeurs vous garantissent un service rapide, fiable, et professionnel.`} </div></div>
            <div id='bloc2_1_2'  className={ "flex flex-col items-start p-4"}     > <div id='bloc2_1_2_0'  className={ "font-bold text-lg"}     >{`Chauffeur Privé et Groupes `} </div>
              <div id='bloc2_1_2_1'  className={ " my-2 text-lg text-left max-w-5/6"}     >{`BMN Taxis vous propose un service de chauffeur privé pour vos besoins personnels ou professionnels, ainsi que des solutions de transport pour groupes. Profitez d’un voyage confortable et personnalisé, que ce soit pour un événement, une réunion ou une sortie en groupe.`} </div></div></div></div><div id='bloc3'  className={ "w-full p-6 flex flex-col justify-center md:flex-row items-center bg-primary_color text-white"}     > <div id='bloc3_0'  className={ "flex flex-col md:w-1/3 centre  items-center justify-center"}     > <FontAwesomeIcon  id='bloc3_0_0'  icon={ fa.faRoad}      className={ "my-2 text-lg text-center w-32 h-32"}  /></div>
          <div id='bloc3_1'  className={ "text-4xl font-bold my-2 px-4 grow text-center"}     >{`BMN Taxis, votre confort et sérénité au cœur de chaque trajet. `} </div>
          <div id='bloc3_2'  className={ "flex flex-col md:w-1/3 centre  items-center justify-center"}     > </div></div><div id='bloc4'  className={ "p-2 w-screen flex flex-col items-center px-6 bg-gray-200"}    onStart = { action_event_469_bloc4} > <div id='bloc4_0'  className={ "w-full p-2 text-xl font-bold text-center"}     >{`Les meilleurs recommandations sont celles de nos clients`} </div>
          <div id='bloc4_1'  className={ "p-2 m-2 bg-yellow-500 w-40 rounded-full"}     > </div>
          <div id='bloc4_2'  className={ "bg-gray-800 text-white font-bold p-4 m-4"}     >{ content["Resume"]} </div>
          <div id='bloc4_3'  className={ "p-2 flex md:flex-row flex-col gap-2 my-4"}     >  {  content["Avis"] &&  content["Avis"]?.map(  ( obj_bloc4_3_0x0,obj_bloc4_3_0x0_index)=>   <div id='bloc4_3_0x0' key= { obj_bloc4_3_0x0_index} className={ "w-full p-2 bg-white flex flex-col rounded-xl shadow items-center p-2 w-full bg-gray-500 my-2"}     > <img  id='bloc4_3_0x0_0' src={ obj_bloc4_3_0x0["profile_photo_url"] } className={ "bg-white h-20 w-20 rounded-full mt-2   object-cover"}     alt="undefined" />
              <div id='bloc4_3_0x0_1'  className={ "w-full p-2 font-bold text-center"}     >{ obj_bloc4_3_0x0["author_name"]} </div>
              <div id='bloc4_3_0x0_2'  className={ "w-full p-2 bg-white flex centre  items-center justify-center"}     > <FontAwesomeIcon  id='bloc4_3_0x0_2_0x0'  icon={ fa.faStar}      className={ "text-yellow-500"}  />
        <FontAwesomeIcon  id='bloc4_3_0x0_2_0x1'  icon={ fa.faStar}      className={ "text-yellow-500"}  />
        <FontAwesomeIcon  id='bloc4_3_0x0_2_0x2'  icon={ fa.faStar}      className={ "text-yellow-500"}  />
        <FontAwesomeIcon  id='bloc4_3_0x0_2_0x3'  icon={ fa.faStar}      className={ "text-yellow-500"}  />
        <FontAwesomeIcon  id='bloc4_3_0x0_2_0x4'  icon={ fa.faStar}      className={ "text-yellow-500"}  /></div>
              <div id='bloc4_3_0x0_3'  className={ "w-full text-center p-2 px-4"}     >{ obj_bloc4_3_0x0["text"]} </div>
              <div id='bloc4_3_0x0_4'  className={ "p-2 flex flex-row gap-1 w-full justify-between items-center"}     > <img  id='bloc4_3_0x0_4_0' src="https://fs.appisyou.com/users/1/google21720508292737.png" className={ "h-8   object-cover"}     alt="undefined" />
                <div id='bloc4_3_0x0_4_1'  className={ "p-2 text-sm"}     >{ obj_bloc4_3_0x0["relative_time_description"]} </div></div></div>  ) }</div>
          <a    href="https://www.google.com/maps/place/BMN+TAXI%E2%80%99S/@46.2016223,5.2432073,17z/data=!4m8!3m7!1s0xaca0a1a9f866f005:0x183ea28c95b3116c!8m2!3d46.2016223!4d5.2457822!9m1!1b1!16s%2Fg%2F11sy_621hp?entry=ttu" target='_blank'><div id='bloc4_4'  className={ "p-2 bg-white rounded-full border-2 text-sm font-bold border-gray-500 m-4 mb-8"}    onClick = { bloc4_4_onClick } >{`VOIR TOUS LES AVIS`} </div></a></div><div id='bloc5'  className={ "bg-primary_color text-white p-2"}     > <div id='bloc5_0'  className={ "w-full p-6 flex flex-col justify-center md:flex-row items-center"}     > <div id='bloc5_0_0'  className={ "flex flex-col md:w-1/3 centre  items-center justify-center"}     > <FontAwesomeIcon  id='bloc5_0_0_0'  icon={ fa.faSmileWink}      className={ "my-2 text-lg text-center h-20 w-20"}  /></div>
            <div id='bloc5_0_1'  className={ "font-bold my-2 px-4 grow text-center text-2xl"}     >{`Bien Plus Qu'un Trajet : Une Expérience ✨`} </div>
            <div id='bloc5_0_2'  className={ "flex flex-col md:w-1/3 centre  items-center justify-center"}     > </div></div>
          <div id='bloc5_1'  className={ "w-full p-2 text-lg px-6 whitespace-pre-line text-center"}     >{`Disponible 24/7, BMN Taxis vous offre un service rapide, sûr et confortable pour tous vos déplacements.
  La société vous propose toute sorte de trajet qu'il soit privé, professionnel ou médical avec nos taxis conventionnés.
  Nous proposons également du transport de colis qu'il soit programmé ou ou urgent, nous sommes là pour vous.
  Faites confiance BMN Taxis pour vous emmener là ou vous devez être, en toute sérénité. Réservez votre course dès maintenant ! 
  
  BMN Taxis plus qu'un trajet : une expérience !`} </div></div><div id='bloc6'  className={ "p-2 bg-white flex flex-col centre  items-center justify-center"}     > <div id='bloc6_0'  className={ "w-full p-2 bg-white font-medium text-2xl flex flex-col text-center"}     >{`Contact`} </div>
          <div id='bloc6_1'  className={ "p-2 flex justify-center"}     > <div id='bloc6_1_0'  className={ "p-2 bg-white mb-10 w-3/4"}     >{`N'hésitez pas à nous envoyer un message afin de planifier votre trajet à l'avance`} </div></div>
          <div id='bloc6_2'  className={ "p-2 bg-white flex flex-col centre max-w-full p-2 flex flex-col  items-center justify-center"}     > <InputText id={"input_bloc6_2_0"} label={"Nom"} onChange={ (value)=>{
    
    
                      
                          
          setInternal("MessageContact",{...(content["MessageContact"] ?? {} ),"Nom":value})
        
                        
                   } } value={(content[ "MessageContact"] &&  content[ "MessageContact"]["Nom"]) ?? ''} className={"w-full p-2 bg-white"} placeholder={"Nom"} />
            <InputPhoneNumber  id={"input_bloc6_2_1"} label={"Téléphone"} onChange={ (value)=>{
    
    
                      
                          
          setInternal("MessageContact",{...(content["MessageContact"] ?? {} ),"Téléphone":value})
        
                        
                   } } value={(content[ "MessageContact"] &&  content[ "MessageContact"]["Téléphone"]) ?? ''} className={"p-2 flex flex-row justify-start w-full max-w-full"} placeholder={"Téléphone"}  />
            <InputText id={"input_bloc6_2_2"} label={"Adresse Mail"} onChange={ (value)=>{
    
    
                      
                          
          setInternal("MessageContact",{...(content["MessageContact"] ?? {} ),"Adresse Mail":value})
        
                        
                   } } value={(content[ "MessageContact"] &&  content[ "MessageContact"]["Adresse Mail"]) ?? ''} className={"w-full p-2 bg-white"} placeholder={"Adresse Mail"} />
            <InputTextArea id={"input_bloc6_2_3"} label={"Message"} onChange={ (value)=>{
    
    
                      
                          
          setInternal("MessageContact",{...(content["MessageContact"] ?? {} ),"Message":value})
        
                        
                   } } value={(content[ "MessageContact"] &&  content[ "MessageContact"]["Message"]) ?? ''} className={"p-2 flex flex-row justify-start h-40 w-full max-w-full"} placeholder={"Message"}  />
            <div id='bloc6_2_4'  className={ "flex mx-52 p-3 text-gray-400 mb-2 justify-center"}     > <div id='bloc6_2_4_0'  className={ "text-white font-semibold py-2 rounded-2xl px-10 inline-block bg-secondary_color appbutton"}    onClick = { action_event_437_bloc6_2_4_0} >{`ENVOYER`} </div></div></div></div><div id='bloc7'  className={ "w-full bg-black bg-primary_color flex flex-row justify-center text-secondary_color"}     > <div id='bloc7_0'  className={ "p-2 h grid grids-cols-1 md:grid-cols-2 gap-1 lg:grid-cols-3 pb-40"}     > <div id='bloc7_0_0'  className={ " flex flex-col items-start p-4"}     > <div id='bloc7_0_0_0'  className={ "text-xl font-bold"}     >{`Gares`} </div>
              <div id='bloc7_0_0_1'  className={ "whitespace-pre-line text-left"}     >{`Gare SNCF de LYON-PART-DIEU
      Gare SNCF de LYON-PERRACHE
      Gare SNCF de LYON-VAISE
      Gare routière de LYON-perrache
      Gare SNCF de BOURG EN BRESSE
      Gare de Genève Cornavin `} </div></div>
            <div id='bloc7_0_1'  className={ " flex flex-col items-start p-4"}     > <div id='bloc7_0_1_0'  className={ "font-bold text-xl"}     >{`Hôpitaux`} </div>
              <div id='bloc7_0_1_1'  className={ "whitespace-pre-line text-left"}     >{`Hôpital Édouard herriot 
      Centre Léon Bérard 
      Hôpital de la croix rousse 
      Hôpital saint-Joseph saint Luc 
      Hôpital privé jean mermoz
      Hôpital privé natecia 
      Hôpital Louis Pradel 
      Clinique du parc
      Hôpital neurologique pierre Wertheimer
      Hôpital Lyon Sud
      Hôpital Fleyriat
      Clinique Convert `} </div>
              <div id='bloc7_0_1_2'       > </div></div>
            <div id='bloc7_0_2'  className={ " flex flex-col items-start p-4"}     > <div id='bloc7_0_2_0'  className={ "font-bold text-xl"}     >{`Aéroports `} </div>
              <div id='bloc7_0_2_1'  className={ "whitespace-pre-line text-left"}     >{`Aéroport de Lyon saint éxupery
      Aéroport international de Genève 
      Aéroport de Dole-Jura`} </div></div>
            <Link    to="/legal"><div id='bloc7_0_3'  className={ "font-bold text-xl text-left p-4 underline"}    onClick = { bloc7_0_3_onClick } >{`Mentions légales`} </div></Link></div></div></>
  
}

export default Component111Page;
